import React, { useState } from 'react';

import { TextProps, TextPropsSchema } from '@usewaypoint/block-text';

import BaseSidebarPanel from './helpers/BaseSidebarPanel';
import BooleanInput from './helpers/inputs/BooleanInput';
import TextInput from './helpers/inputs/TextInput';
import MultiStylePropertyPanel from './helpers/style-inputs/MultiStylePropertyPanel';
import MergeFields from './helpers/MergeFields';

import {documentDeferHistory} from '../../../../documents/editor/EditorContext';

// based on https://raw.githubusercontent.com/facebook/lexical/refs/tags/v0.17.2-nightly.20240920.0/examples/react-rich/src/App.tsx
import {AutoFocusPlugin} from '@lexical/react/LexicalAutoFocusPlugin';
import {LexicalComposer} from '@lexical/react/LexicalComposer';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {ContentEditable} from '@lexical/react/LexicalContentEditable';
import {LexicalErrorBoundary} from '@lexical/react/LexicalErrorBoundary';
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin';
import {LinkPlugin} from '@lexical/react/LexicalLinkPlugin';
import {ListPlugin} from '@lexical/react/LexicalListPlugin';
import {$generateHtmlFromNodes, $generateNodesFromDOM} from '@lexical/html';

import ToolbarPlugin from './ToolbarPlugin';


import {$getRoot, $insertNodes} from 'lexical';
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin';

import {ListNode, ListItemNode} from '@lexical/list';
import {LinkNode} from '@lexical/link';

type TextSidebarPanelProps = {
  data: TextProps;
  setData: (v: TextProps) => void;
};
export default function TextSidebarPanel({ data, setData }: TextSidebarPanelProps) {
  const [, setErrors] = useState<Zod.ZodError | null>(null);
  const [hasLoadedInitialContent, setHasLoadedInitialContent] = useState(false);

  const updateData = (d: unknown) => {
    const res = TextPropsSchema.safeParse(d);
    if (res.success) {
      setData(res.data);
      setErrors(null);
    } else {
      setErrors(res.error);
    }
  };
  
	// parts based on https://raw.githubusercontent.com/facebook/lexical/refs/tags/v0.17.2-nightly.20240920.0/examples/react-rich/src/App.tsx
	
	const placeholder = 'Enter some text...';
	
	const editorConfig = {
		// based on https://raw.githubusercontent.com/facebook/lexical/refs/tags/v0.17.2-nightly.20240920.0/examples/react-rich/src/ExampleTheme.ts
		theme: {
		  code: 'editor-code',
		  heading: {
			h1: 'editor-heading-h1',
			h2: 'editor-heading-h2',
			h3: 'editor-heading-h3',
			h4: 'editor-heading-h4',
			h5: 'editor-heading-h5',
		  },
		  image: 'editor-image',
		  link: 'editor-link',
		  list: {
			listitem: 'editor-listitem',
			nested: {
			  listitem: 'editor-nested-listitem',
			},
			ol: 'editor-list-ol',
			ul: 'editor-list-ul',
		  },
		  ltr: 'ltr',
		  paragraph: 'editor-paragraph',
		  placeholder: 'editor-placeholder',
		  quote: 'editor-quote',
		  rtl: 'rtl',
		  text: {
			bold: 'editor-text-bold',
			code: 'editor-text-code',
			hashtag: 'editor-text-hashtag',
			italic: 'editor-text-italic',
			overflowed: 'editor-text-overflowed',
			strikethrough: 'editor-text-strikethrough',
			underline: 'editor-text-underline',
			underlineStrikethrough: 'editor-text-underlineStrikethrough',
		  }
		},
		nodes: [ListNode, ListItemNode, LinkNode]
	};
	
	const ChangeHandler = () => {
		const htmlEditor = useLexicalComposerContext()[0];
	
		if (!hasLoadedInitialContent) {
			// based on https://raw.githubusercontent.com/facebook/lexical/refs/tags/v0.17.2-nightly.20240920.0/packages/lexical-website/docs/concepts/serialization.md
			htmlEditor.update(() => {
			  const parser = new DOMParser();
			  const dom = parser.parseFromString(data.props.text, 'text/html');

			  const nodes = $generateNodesFromDOM(htmlEditor, dom);

			  $getRoot().clear().select();

			  $insertNodes(nodes);
			});
			
			setHasLoadedInitialContent(true);
		}
	
		const onChange = () => {
			documentDeferHistory();
			
			// based on https://raw.githubusercontent.com/facebook/lexical/refs/tags/v0.17.2-nightly.20240920.0/README.md
			htmlEditor.read(() => {
				const text = $generateHtmlFromNodes( htmlEditor, null );
				updateData({ ...data, props: { ...data.props, text } });
			});
		};
		
		return <OnChangePlugin onChange={onChange} />;
	};
	
	

  return (
    <BaseSidebarPanel title="Text block">
	  
	  <LexicalComposer initialConfig={editorConfig}>
		  <div className="editor-container">
			<ToolbarPlugin />
			<div className="editor-inner">
			  <RichTextPlugin
				contentEditable={
				  <ContentEditable
					className="editor-input"
					aria-placeholder={placeholder}
					placeholder={
					  <div className="editor-placeholder">{placeholder}</div>
					}
				  />
				}
				ErrorBoundary={LexicalErrorBoundary}
			  />
			  <AutoFocusPlugin />
			  <ListPlugin />
			  <LinkPlugin />
			  <ChangeHandler />
			</div>
		  </div>
		</LexicalComposer>
	
	  <MergeFields />

      <MultiStylePropertyPanel
        names={['color', 'backgroundColor', 'fontFamily', 'fontSize', 'fontWeight', 'textAlign', 'padding']}
        value={data.style}
        onChange={(style) => updateData({ ...data, style })}
      />
    </BaseSidebarPanel>
  );
}
